<template>
  <b-modal
    id="tab"
    ok-only
    ok-title="Save"
    centered
    title="Create Language"
    v-model="isOpen"
    @ok="save"
  >
    <b-form-group label="Name" label-for="h-name" label-cols-md="4">
      <b-form-input
        id="h-wed"
        type="text"
        v-model="language.name"
        placeholder="Name"
      />
    </b-form-group>
    <b-form-group label="Code" label-for="h-code" label-cols-md="4">
      <b-form-input
        id="h-code"
        type="text"
        v-model="language.code"
        placeholder="Code"
      />
    </b-form-group>
    <b-form-group label="Flag" label-for="h-flag" label-cols-md="4">
      <b-form-input
        id="h-flag"
        type="text"
        v-model="language.flag"
        placeholder="Flag"
      />
    </b-form-group>
    <b-form-group label="Active" label-for="h-active" label-cols-md="4">
      <b-form-checkbox
        id="h-active"
        v-model="language.native11_active"
      />
    </b-form-group>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      isUpdate: false,
      language: {},
    };
  },
  methods: {
    openModal(editLanguage) {
      if (editLanguage) {
        this.language = editLanguage;
        this.isUpdate = true;
      } else {
        this.language = {};
        this.isUpdate = false;
      }
      this.isOpen = true;
    },
    save() {
      if (this.isUpdate) {
        this.$emit("update", this.language);
        this.isUpdate = false;
      } else {
        this.$emit("create", this.language);
      }
      this.language = {};
    },
  },
};
</script>
