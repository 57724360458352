<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Language" class="mb-3" />
      </b-col>
    </b-row>
    <b-media>
      <section class="py-3">
        <b-row>
          <b-col cols="10">
            <h2 class="mb-2">Language Management</h2>
          </b-col>
          <b-col cols="2">
            <div class="text-center">
              <b-button @click="showForm" variant="primary">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span>Add Language</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </section>
    </b-media>
    <b-card>
      <language-table @edit="edit" @getList="getList" :languages="languages" />
    </b-card>
    <language-form-modal
      @update="update"
      @create="create"
      ref="languageFormModal"
    />
  </div>
</template>

<script>
import service from "../service";
import LanguageFormModal from "../views/_components/LanguageFormModal.vue";
import LanguageTable from "../views/_components/LanguageTable.vue";

export default {
  components: {
    LanguageFormModal,
    LanguageTable,
  },
  data() {
    return {
      current_page: 1,
      total_items: 0,
      items_perpage: 50,
      languages: [],
      editLanguage: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let response = await service.getList({
        page: this.current_page,
        limit: this.items_perpage,
      });
      if (response.data.type === "DATA") {
        if (this.languages.length > 0) {
          this.$store.dispatch("language/setLanguage");
        }
        this.languages = response.data.data.list;
        this.total_items = response.data.data.total;
      }
    },
    showForm(type) {
      if (type != "update") {
        this.editLanguage = null;
      }
      this.$refs.languageFormModal.openModal(this.editLanguage);
    },
    async create(language) {
      let response = await service.create(language);
      if (response.data.type == "DATA") {
        this.$store.dispatch("pushSuccessNotify", { text: "Successfully!" });
        this.getList();
      }
    },
    edit(languageInfo) {
      this.editLanguage = languageInfo;
      this.showForm("update");
    },
    async update(info) {
      await service.update({ data: JSON.stringify(info) });
      this.getList();
    },
  },
};
</script>
